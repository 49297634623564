import moment from "moment";

import {
  orderServiceCountQuery,
  orderServiceCreateMutation,
  orderServiceCustomMutation,
  orderServiceCustomQuery,
  orderServiceFindOneQuery,
  orderServiceListQuery,
  orderServiceUpdateMutation,
  orderServiceTable,
} from "./orderService";

const model = "orders";

export const useOrder = orderServiceFindOneQuery({ model });
export const useOrderList = orderServiceListQuery({ model });
export const useOrderCount = orderServiceCountQuery({ model });
export const useCreateOrder = orderServiceCreateMutation({ model });
export const useUpdateOrder = orderServiceUpdateMutation({ model });
export const useFulfillOrder = orderServiceCustomMutation({
  model,
  path: "fulfill",
  method: "put",
});

export const useFindByShippingOrderId = orderServiceCustomQuery({
  model,
  path: "findByShippingOrderId/:id",
});

export const useOrderTable = orderServiceCustomQuery({
  model,
  path: "table",
});

export const exportOrders = ({ _where, _sort, _limit }) => {
  return orderServiceTable({
    model,
    _where,
    _sort,
    _limit,
    as_csv: true,
  });
};

export const useUpcomingOrders = orderServiceCustomQuery({
  model: `upcoming-${model}`,
  path: "table",
});

export const useUpcomingOrdersCount = orderServiceCustomQuery({
  model: `upcoming-${model}`,
  path: "count",
});

export const exportUpcomingOrders = (queryParams) => {
  return orderServiceTable({
    model: `upcoming-${model}`,
    as_csv: true,
    ...queryParams,
  });
};
